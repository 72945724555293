// Stylesheets
import './assets/scss/site.scss';
 
import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {nav} from './assets/modules/nav';
import {lazyload} from './assets/modules/lazyload';
import {initPhotoSwipeFromDOM} from './assets/modules/photoswipe-init';
import {reframe} from './assets/modules/reframe';
import {Pjax} from 'pjax-api';
 
const getOffset = (el) => {
  const box = el.getBoundingClientRect();
  return {
    top: box.top + window.pageYOffset - document.documentElement.clientTop,
    left: box.left + window.pageXOffset - document.documentElement.clientLeft
  }
}

let removeCurrentOffsetTop = '';

const click = function(e) {
  const el = this;
  if(el.classList.contains('is-current')) {
    document.getElementById('single-content').remove();
    el.classList.add('is-current-remove-reserve', 'setHref');
    const removeCurrentOffset = getOffset(el);
    removeCurrentOffsetTop = removeCurrentOffset.top;
  } else {
    const current = document.querySelector('.js-post-trigger.is-current');
    if(current) {
      current.classList.add('setHref', 'is-current-remove-reserve');
    }
    el.classList.add('removeHref', 'is-current-add-reserve');
  }
}

const postTrigger = () => {
  const trigger = document.getElementsByClassName('js-post-trigger');
  [].forEach.call(trigger, function(el) {
    el.removeEventListener('click', click, false);
    el.addEventListener('click', click, false);
  });
}

const scrollPos = () => {
  const current = document.querySelector('.js-post-trigger.is-current');
  if(current) {
    const currentOffset = getOffset(current);
    const currentOffsetTop = currentOffset.top;
    const ww = window.document.documentElement.clientWidth;
    if (ww >= 1024) {
      window.scroll(0, currentOffsetTop);
    } else {
      const header = document.getElementById('identity');
      const headerHeight = header.clientHeight;
      window.scroll(0, currentOffsetTop-headerHeight);
    }
  } else if(removeCurrentOffsetTop) {
    const ww = window.document.documentElement.clientWidth;
    if (ww >= 1024) {
      window.scroll(0, removeCurrentOffsetTop);
    } else {
      const header = document.getElementById('identity');
      const headerHeight = header.clientHeight;
      window.scroll(0, removeCurrentOffsetTop-headerHeight);
    }
    removeCurrentOffsetTop = '';
  }
}

const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  lazyload();
  initPhotoSwipeFromDOM('.js-pswp-container');
  reframe('.js-oembed-container > iframe');
  postTrigger();
  scrollPos();
}

nav();
init();
 
new Pjax({
  areas: [
    '#single-content',
    '#content',
    '#main',
    'body'
  ],
  update: {
    css: false,
    script: false
  }
});
 
document.addEventListener('pjax:content', function(e) {
  const removeReserve = document.querySelector('.js-post-trigger.is-current-remove-reserve');
  if(removeReserve) removeReserve.classList.remove('is-current', 'is-current-remove-reserve');

  const addReserve = document.querySelector('.js-post-trigger.is-current-add-reserve');
  if(addReserve) {
    addReserve.classList.add('is-current');
    addReserve.classList.remove('is-current-add-reserve');
  }

  if(document.getElementById('single-content')) {
    const current = document.querySelector('.js-post-trigger.is-current')
    const cloneBase = document.getElementById('single-content')
    const clone = cloneBase.cloneNode(true);
    document.getElementById('single-content').remove();
    current.parentNode.insertBefore(clone, current.nextElementSibling);
  }

  const setHref = document.querySelector('.js-post-trigger.setHref');
  if(setHref) {
    setHref.setAttribute('href', setHref.dataset.href);
    setHref.classList.remove('setHref');
  }

  const removeHref = document.querySelector('.js-post-trigger.removeHref');
  if(removeHref) {
    removeHref.setAttribute('href', '/');
    removeHref.classList.remove('removeHref');
  }

  const trigger = document.getElementsByClassName('js-post-trigger');
  [].forEach.call(trigger, function(el) {
    el.removeEventListener('click', click, false);
  });
});
 
document.addEventListener('pjax:ready', function(e) {
  document.body.classList.remove('is-nav-active');
  init();
  gtag('config', ga_code, {'page_path' : location.pathname});
});