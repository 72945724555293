export function nav() {
  const el = document.getElementById(`nav-trigger`);
  let scTop = 0;
  document.body.classList.remove('is-nav-active');
  el.addEventListener('click', function(e) {
    e.preventDefault();
    if(document.body.classList.contains('is-nav-active')) {
      document.body.classList.remove('is-nav-active');
      setTimeout(() => {
        window.scroll(0, scTop);
        scTop = 0;
      },0);
    } else {
      scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      window.scrollTo(0, 0);
      document.body.classList.add('is-nav-active');
    }
  });
}