export function hover() {
  const hov = document.querySelectorAll('a, button, .hov');
  if(IS_TOUCH) {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('touchstart', function(e) {
        this.classList.add('hover');
      });
      el.addEventListener('touchend', function(e) {
        this.classList.remove('hover');
      });
    };
  } else {
    for (let i = 0; i < hov.length; i++) {
      const el = hov[i];
      el.addEventListener('mouseenter', function(e) {
        this.classList.add('hover');
      });
      el.addEventListener('mouseleave', function(e) {
        this.classList.remove('hover');
      });
    };
  }
}